/*
================================================================
IMPORTS
================================================================
*/

// import '../sass/style-v1.0.scss';

const InfiniteScroll = require('infinite-scroll');


import 'lazysizes';

window.lazySizesConfig = window.lazySizesConfig || {};
lazySizesConfig.hFac = 1.5;
/*
================================================================
VARIABLES
================================================================
*/

var mobileNavToggle = document.querySelector('.mobile-nav-toggle');
var mobileNavContainer = document.querySelector('.mobile-nav-wrapper');
var body = document.querySelector('body');

/*
================================================================
MOBILE NAVIGATION
================================================================
*/


if (mobileNavToggle) {
    mobileNavToggle.addEventListener('click', toggleMobileNav);
}

function toggleMobileNav() {
    var mobileNavIsOpen = body.classList.contains("mobile-menu--open");

    if (mobileNavIsOpen) {
        closeMobileNav();
    } else {
        openMobileNav();
    }
}

function openMobileNav() {
    body.classList.add('mobile-menu--open');
    mobileNavToggle.setAttribute('aria-expanded', true);
    mobileNavContainer.focus();
}

function closeMobileNav() {
    body.classList.remove('mobile-menu--open');
    mobileNavToggle.setAttribute('aria-expanded', false);
    mobileNavToggle.focus();
}


// /*
// ================================================================
// LOAD MORE
// ================================================================
// */

let elem = document.querySelector('.blog-posts');
let infScroll = new InfiniteScroll( elem, {
  // options
  path: '.load-more-button',
  append: '.article-card',
  history: true,
  scrollThreshold: false,
  button: '.load-more-button'
});

infScroll.on( 'append', function( body, path, items, response ) {
    for (var i = 0; i < items.length; i++) {
        // console.log(items[i]);
    }

});